<template>
  <div class="container" v-title :data-title="$t('i18n.elevatorList')">
    <div id="outer-title">{{ $t("i18n.elevatorList") }}</div>
    <div v-loading="loading">
      <jl-table
        :tableData="tableData"
        @init="init"
        :options="options"
        :columns="columns"
        :operates="operates"
        :total="total"
        :height="$defined.HEIGHT - 300 + 'px'"
      >
      </jl-table>
    </div>
  </div>
</template>
<script>
import socket from "../../../public/js/socket";
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import jlTable from "../../components/table";
export default {
  name: "DetectionBoard",
  components: {
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const router = useRouter();
    const state = reactive({
      formInline: {
        pageNumber: 1,
        pageSize: 10,
      },
      // table数据
      tableData: [],
      options: {
        // table 的参数
        isAdd: false, // 是否启用新增功能
        isEdit: false, // 是否启用编辑功能
        isDelete: false, // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "number",
          label: "elevatorNo",
          align: "center",
          search: true,
          type: "input",
          formatter: (row) => {
            return `<a>${row.number}</a>`;
          },
          method: (row) => {
            router.push({
              path: "/elevator/addEditElevator",
              query: { flag: 1, id: row.id },
            });
            // router.push({
            //   path: "/detection/partsMaintenance",
            //   query: { id: row.id },
            // });
          },
        },
        {
          prop: "alias",
          label: "address",
          align: "left",
          width: 300,
        },
        {
          prop: "motoring",
          label: "runningState",
          align: "center",
          search: true,
          type: "select",
          props: { label: "code", value: "value" },
          data: [],
          uploadUrl: "elevatorStatus",
          value: "4",
          isUpdate: true,
          formatter: (row) => {
            return row.status == 10 ? t("i18n.online") : t("i18n.offline");
          },
        },
        {
          label: "malfunctionState",
          align: "center",
          formatter: (row) => {
            return row.faultStatus == 20
              ? t("i18n.malfunction")
              : t("i18n.normal");
          },
        },
        {
          label: "reconditionState",
          align: "center",
          formatter: (row) => {
            return row.maintenanceStatus == 20
              ? t("i18n.recondition")
              : t("i18n.normal");
          },
        },
        {
          prop: "floor",
          label: "currentFloor",
          align: "center",
        },
        {
          label: "upDown",
          align: "center",
          formatter: (row) => {
            if (row.flow == "10") {
              return `<img src="../../assets/flowupgreen.png"`;
            } else if (row.flow == "01") {
              return `<img src="../../assets/flowdowngreen.png"`;
            }
          },
        },
      ],
      operates: {
        // 列操作按钮
        width: proxy.$defined.WIDTH > 768 ? 200 : 80,
        fixed: "right",
        list: [
          {
            id: "1",
            label: "view",
            icon: "el-icon-view",
            show: true,
            plain: false,
            disabled: false,
            type: "inner",
            method: (index, row) => {
              router.push({
                path: "/detection/detectioncou",
                query: { id: row.id },
              });
            },
          },
        ],
      },
      total: 0,
      loading: true,
      editData: {},
    });

    const init = async (params) => {
      state.loading = true;
      const { data } = await proxy.$api.supervision.detectionPage(params);
      state.tableData = data.records;
      socket.set(state.tableData, (ele) => {
        if (Array.isArray(ele)) {
          ele.map((item) => {
            state.tableData[item.index] = JSON.parse(JSON.stringify(item));
          });
        } else {
          state.tableData[ele.index] = JSON.parse(JSON.stringify(ele));
        }
      });
      state.total = data.total;
      state.loading = false;
    };

    return {
      ...toRefs(state),
      init,
    };
  },
};
</script>
